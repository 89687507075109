@-webkit-keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1); }
  10% {
    -webkit-transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1);
            transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1); }
  50% {
    -webkit-transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1);
            transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1); }
  100% {
    -webkit-transform: translate3d(0, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(0, -50%, 0) scale3d(1, 1, 1); }
  50% {
    -webkit-transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1);
            transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1); }
  10% {
    -webkit-transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1);
            transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1); }
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1); } }

@keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1); }
  10% {
    -webkit-transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1);
            transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1); }
  50% {
    -webkit-transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1);
            transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1); }
  100% {
    -webkit-transform: translate3d(0, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(0, -50%, 0) scale3d(1, 1, 1); }
  50% {
    -webkit-transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1);
            transform: translate3d(0, -15%, 0) scale3d(0.7, 1.2, 1); }
  10% {
    -webkit-transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1);
            transform: translate3d(0, -5, 0) scale3d(1.2, 0.7, 1); }
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1); } }

.menu-btn {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 35px;
  top: 35px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  transition: 0.9s; }
  .menu-btn:hover {
    color: gold;
    transition: 0.9s; }
  .menu-btn .btn-line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    transition: all 0.5s ease-out; }
  .menu-btn.close {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .menu-btn.close .btn-line:nth-child(1) {
      -webkit-transform: rotate(45deg) translate(5px, 5px);
              transform: rotate(45deg) translate(5px, 5px); }
    .menu-btn.close .btn-line:nth-child(2) {
      opacity: 0; }
    .menu-btn.close .btn-line:nth-child(3) {
      -webkit-transform: rotate(135deg) translate(-6px, 6px);
              transform: rotate(135deg) translate(-6px, 6px); }

.menu {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 1;
  visibility: hidden; }
  .menu.show {
    visibility: visible; }
  .menu-branding, .menu-nav {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    float: left;
    width: 50%;
    height: 100vh;
    overflow: hidden; }
  .menu-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    transition: all 0.5s ease-out; }
    .menu-nav.show {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .menu-branding {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    transition: all 0.5s ease-out; }
    .menu-branding.show {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .menu .nav-item {
    -webkit-transform: translate3d(600px, 0, 0);
            transform: translate3d(600px, 0, 0);
    transition: all 0.5s ease-out; }
    .menu .nav-item.show {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }

.nav-item:nth-child(1) {
  transition-delay: 0.1s; }

.nav-link {
  display: inline-block;
  position: relative;
  font-size: 30px;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.5s ease-out; }

.nav-item:nth-child(2) {
  transition-delay: 0.2s; }

.nav-link {
  display: inline-block;
  position: relative;
  font-size: 30px;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.5s ease-out; }

.nav-item:nth-child(3) {
  transition-delay: 0.3s; }

.nav-link {
  display: inline-block;
  position: relative;
  font-size: 30px;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.5s ease-out; }

.nav-item:nth-child(4) {
  transition-delay: 0.4s; }

.nav-link {
  display: inline-block;
  position: relative;
  font-size: 30px;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.5s ease-out; }

* {
  box-sizing: border-box; }

@font-face {
  font-family: "Gotham-Medium";
  src: url(/static/media/Gotham-Medium.cd291075.eot);
  /* IE9*/
  src: url(/static/media/Gotham-Medium.cd291075.eot?#iefix) format("embedded-opentype"), url(/static/media/Gotham-Medium.ff00f4eb.woff2) format("woff2"), url(/static/media/Gotham-Medium.a1e10f36.woff) format("woff"), url(/static/media/Gotham-Medium.3a47f5f0.ttf) format("truetype"), url(/static/media/Gotham-Medium.049b9251.svg#Gotham-Medium) format("svg");
  /* iOS 4.1- */ }

@font-face {
  font-family: "Gotham-Light";
  src: url(/static/media/Gotham-Light.fa9eca41.eot);
  /* IE9*/
  src: url(/static/media/Gotham-Light.fa9eca41.eot?#iefix) format("embedded-opentype"), url(/static/media/Gotham-Light.b15051a0.woff2) format("woff2"), url(/static/media/Gotham-Light.2e89a17d.woff) format("woff"), url(/static/media/Gotham-Light.fc74760c.ttf) format("truetype"), url(/static/media/Gotham-Light.267f99f5.svg#Gotham-Light) format("svg");
  /* iOS 4.1- */ }

@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham.e494e4c5.eot);
  /* IE9*/
  src: url(/static/media/Gotham.e494e4c5.eot?#iefix) format("embedded-opentype"), url(/static/media/Gotham.b745034c.woff2) format("woff2"), url(/static/media/Gotham.0ad605af.woff) format("woff"), url(/static/media/Gotham.bd317adf.ttf) format("truetype"), url(/static/media/Gotham.618ac477.svg#Gotham) format("svg");
  /* iOS 4.1- */ }

body {
  height: 100%;
  margin: 0;
  font-family: "Gotham-Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5; }

h1,
h2,
h3 {
  margin: 0;
  font-weight: 300; }
  h1.lg-heading,
  h2.lg-heading,
  h3.lg-heading {
    font-size: 5rem; }
  h1.sm-heading,
  h2.sm-heading,
  h3.sm-heading {
    margin-bottom: 2rem;
    padding: 0.2rem 1rem; }

.actions {
  position: fixed;
  z-index: 2;
  width: 100%; }

main {
  padding: 5rem;
  min-height: calc(100vh - 60px); }
  main .icons {
    margin-top: 1rem; }
    main .icons a {
      padding: 0.4rem;
      transition: all 0.5s ease-out; }
  main#home {
    overflow: hidden; }
    main#home h1 {
      margin-top: 20vh; }

.dark-mode-toggler {
  position: absolute;
  z-index: 0;
  right: 32px;
  top: 75px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  border: none;
  background: none;
  margin: 0;
  padding: 0; }
  .dark-mode-toggler .fa-stack {
    transition: 0.9s;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  .dark-mode-toggler:hover .fa-stack {
    color: gold;
    transition: 0.9s;
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg); }

.home-button {
  position: absolute;
  z-index: 0;
  right: 32px;
  top: 120px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease-out; }
  .home-button .fa-stack {
    transition: 0.9s; }
  .home-button:hover .fa-stack {
    color: gold;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-animation: jump 0.5s linear alternate infinite;
            animation: jump 0.5s linear alternate infinite;
    transition: all 0.5s ease-out; }

.about-info {
  display: grid;
  grid-gap: 30px;
  grid-template-areas: "bioimage bio bio" "exp-h exp-h exp-h" "job1 job2 job3" "edu-h  skill-h  skill-h" "edu-b skill-1 skill-2";
  grid-template-columns: repeat(3, 1fr); }
  .about-info .bio-image {
    grid-area: bioimage;
    margin: auto;
    border-radius: 50%; }
  .about-info .learning {
    align-items: center;
    text-align: center; }
  .about-info .bio {
    grid-area: bio;
    font-size: 1.5rem; }
  .about-info .exp-h {
    grid-area: exp-h;
    padding: 0.5rem; }
  .about-info .job-1 {
    grid-area: job1; }
  .about-info .job-2 {
    grid-area: job2; }
  .about-info .job-3 {
    grid-area: job3; }
  .about-info .job {
    padding: 0.5rem; }
  .about-info .edu-h {
    grid-area: edu-h;
    padding: 0.5rem; }
  .about-info .edu-b {
    grid-area: edu-b;
    padding: 0.5rem; }
  .about-info .skill-h {
    grid-area: skill-h;
    padding: 0.5rem; }
  .about-info .skill-1 {
    grid-area: skill-1;
    padding: 0.5rem; }
  .about-info .skill-2 {
    grid-area: skill-2;
    padding: 0.5rem; }
  .about-info .dev-icons {
    font-size: 3rem;
    padding-left: 0;
    list-style: none; }
    .about-info .dev-icons .icon-list-item {
      display: inline-block;
      padding: 1rem; }
      .about-info .dev-icons .icon-list-item .custom-icon {
        transition: all 0.5s ease-out; }
    .about-info .dev-icons i {
      transition: all 0.5s ease-out; }
  .about-info .date-text {
    font-style: italic; }

.projects {
  display: grid;
  grid-gap: 0.7rem;
  grid-template-columns: repeat(3, 1fr);
  /* relevant styles */ }
  .projects img {
    width: 100%;
    transition: all 0.5s ease-out; }
  .projects .img__wrap {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    transition: all 0.5s ease-out; }
    .projects .img__wrap:hover {
      opacity: 1;
      transition: all 0.5s ease-out; }
      .projects .img__wrap:hover .img__description {
        padding-top: 1rem;
        visibility: visible;
        opacity: 1;
        align-items: center;
        transition: all 0.5s ease-out; }
      .projects .img__wrap:hover img {
        opacity: 0.2;
        transition: all 0.5s ease-out; }
  .projects .img__description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    transition: all 0.5s ease-out; }

.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem; }
  .boxes div {
    font-size: 2rem;
    padding: 0.5rem 0.5rem;
    margin-bottom: 3rem;
    transition: all 0.5s ease-out; }
    .boxes div:hover {
      padding: 0.5rem, 1.5rem; }
      .boxes div:hover span {
        transition: all 0.5s ease-out; }

.btn, .btn-project, .btn-github {
  display: block;
  padding: 0.5rem 1rem;
  border: 0;
  margin-bottom: 0.3rem; }

#main-footer {
  text-align: center;
  padding: 1rem;
  height: 60px; }

@media screen and (min-width: 1171px) {
  .projects {
    grid-template-columns: repeat(3, 1fr); } }

@media screen and (min-width: 769px) and (max-width: 1170px) {
  main {
    align-items: center;
    text-align: center; }
    main .lg-heading {
      line-height: 1;
      margin-bottom: 1rem; }
    main ol,
    main li {
      list-style-position: inside;
      text-align: left;
      margin-bottom: 1rem;
      padding: 0 0.5rem; }
  .projects {
    grid-template-columns: repeat(2, 1fr); }
  .about-info {
    grid-template-areas: "bioimage" "bio" "exp-h" "job1" "job2" "job3" "edu-h" "edu-b" "skill-h" "skill-1" "skill-2";
    grid-template-columns: 1fr;
    padding: 0rem 2rem;
    font-size: 1rem; } }

@media screen and (max-width: 768px) {
  main {
    align-items: center;
    text-align: center; }
    main .lg-heading {
      line-height: 1;
      margin-bottom: 1rem; }
    main ol,
    main li {
      list-style-position: inside;
      text-align: left;
      margin-bottom: 1rem;
      padding: 0 0.5rem; }
  ul.menu-nav,
  div.menu-branding {
    float: none;
    width: 100%;
    min-height: 0; }
    ul.menu-nav.show,
    div.menu-branding.show {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .menu-nav {
    height: 60vh;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    font-size: 24px; }
  .menu-branding {
    height: 40vh;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .about-info {
    grid-template-areas: "bioimage" "bio" "exp-h" "job1" "job2" "job3" "edu-h" "edu-b" "skill-h" "skill-1" "skill-2";
    grid-template-columns: 1fr;
    padding: 0rem 2rem;
    font-size: 1rem; }
  .projects {
    grid-template-columns: 1fr; }
  .nav-item:nth-child(1) {
    transition-delay: 0.1s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .nav-item:nth-child(2) {
    transition-delay: 0.2s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .nav-item:nth-child(3) {
    transition-delay: 0.3s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .nav-item:nth-child(4) {
    transition-delay: 0.4s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: 300; }
    h1.lg-heading,
    h2.lg-heading,
    h3.lg-heading {
      font-size: 3rem;
      padding-top: 2rem; }
    h1.sm-heading,
    h2.sm-heading,
    h3.sm-heading {
      margin-bottom: 2rem;
      padding: 0.2rem 1rem; }
  .boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem; }
    .boxes div {
      font-size: 1.5rem;
      padding: 0.5rem 0.5rem;
      margin-bottom: 1rem;
      transition: all 0.5s ease-out; }
      .boxes div:hover {
        padding: 0.5rem, 0.5rem; }
        .boxes div:hover span {
          transition: all 0.5s ease-out; }
  #main-footer {
    font-size: 0.7rem; } }

@media screen and (max-width: 500px) {
  main {
    padding: 2rem; }
    main #home h1 {
      margin-top: 10vh; }
    main .lg-heading {
      margin-top: 1rem;
      font-size: 5rem; }
    main ol,
    main li {
      list-style-position: inside;
      text-align: left;
      margin-bottom: 1rem;
      padding: 0 0.5rem; }
  .projects {
    grid-template-columns: 1fr; }
  .nav-item:nth-child(1) {
    transition-delay: 0.1s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .nav-item:nth-child(2) {
    transition-delay: 0.2s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .nav-item:nth-child(3) {
    transition-delay: 0.3s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .nav-item:nth-child(4) {
    transition-delay: 0.4s; }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s ease-out; }
  .menu-branding {
    height: 40vh;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .menu-nav {
    height: 60vh;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    font-size: 24px; }
  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: 300; }
    h1.lg-heading,
    h2.lg-heading,
    h3.lg-heading {
      font-size: 2rem;
      padding-top: 2rem; }
    h1.sm-heading,
    h2.sm-heading,
    h3.sm-heading {
      margin-bottom: 2rem;
      padding: 0.2rem 1rem; }
  .projects {
    padding: 0rem 3rem 0rem 3rem; }
  .boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem; }
    .boxes div {
      font-size: 1rem;
      padding: 0.5rem 0.5rem;
      margin-bottom: 1rem;
      transition: all 0.5s ease-out; }
      .boxes div:hover {
        padding: 0.5rem, 1.5rem; }
        .boxes div:hover span {
          transition: all 0.5s ease-out; }
  .btn, .btn-project, .btn-github {
    display: block;
    padding: 0.5rem 0.5rem;
    border: 0;
    margin-bottom: 0.3rem; }
  .dark-mode-toggler {
    position: absolute;
    z-index: 0;
    right: 5.5vw;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease-out; }
    .dark-mode-toggler .fa-stack {
      transition: 0.9s;
      -webkit-transform: rotateZ(0deg);
              transform: rotateZ(0deg); }
    .dark-mode-toggler:hover .fa-stack {
      color: gold;
      transition: 0.9s;
      -webkit-transform: rotateZ(-10deg);
              transform: rotateZ(-10deg); }
  .menu-btn {
    display: inline-block;
    position: absolute;
    z-index: 3;
    right: 6vw;
    cursor: pointer;
    transition: all 0.5s ease-out; }
  .home-button {
    position: absolute;
    z-index: 1;
    right: 5.5vw;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease-out;
    border: none;
    background: none;
    margin: 0;
    padding: 0; }
    .home-button .fa-stack {
      transition: 0.9s; }
    .home-button:hover .fa-stack {
      color: gold;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-animation: jump 0.5s linear alternate infinite;
              animation: jump 0.5s linear alternate infinite; }
  #main-footer {
    font-size: 0.7rem;
    padding-right: 5rem;
    padding-left: 5rem; } }

/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1;
}

